@import '../variables';
@import '../flexbox/mixins';

.open-source-card {
  &:hover,
  :focus {
    text-decoration: none !important;
  }
}
.flat-panel {
  .panel {
    box-shadow: none;
    border: none;
    .panel-heading {
      background-color: transparent;
      .panel-icon {
        display: none;
      }
    }
  }
}

.panel {
  &.no-pad {
    padding: 0 !important;
    .panel-content {
      padding: 0 !important;
    }
  }
  .no-results {
    text-align: center;
    line-height: 44px;
  }
  .panel-heading {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $text-icon-dark;
      margin: 0;
    }

    font-weight: 500;
    font-size: $font-size-base;
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    height: 56px;

    .title {
      color: $text-icon-dark;
      font-family: $font-family-header;
      .subtitle {
        color: $text-icon-dark;
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  .panel-content {
    background-color: $panel-bg;
    padding: $input-padding-y $input-padding-x;
  }

  &.segment-overrides {
    border: 1px solid $basic-alpha-16;
    border-radius: $border-radius-lg;
    background-color: $text-icon-light;
  }

  &.no-pad {
    .panel-content {
      padding: 0;
    }
  }
}

.dark {
  .panel {
    .icon {
      color: $dark-color;
    }
    .panel-content {
      background-color: transparent;
      .search-list {
        background-color: $bg-dark400;
        border: 1px solid $white-alpha-16;
        .list-item {
          border-top: 1px solid $white-alpha-16;
          &:first-child {
            border-top: none;
          }
        }
        &.change-request-list{
          background-color: $bg-dark500;
        }
      }

      .chip {
        background-color: $panel-bg-darker;
      }
    }
    .panel-heading {
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: $panel-heading-color-dark;
        margin: 0;
      }
      .title {
        color: $text-icon-light;
        .subtitle {
          color: $text-icon-light;
        }
      }
      color: $text-icon-light;
      .ion-ios-information-circle {
        color: $panel-icon-background-color;
      }
    }
    a {
      color: $dark-highlight-color;
      &:hover {
        color: $dark-highlight-color !important;
        &.text-white {
          color: white !important;
        }
      }
    }
    .list-item:hover a,.btn-link {
      color: $primary !important;
    }
    .panel-icon {
      background-color: $panel-icon-background-color-dark;
      border-color: $panel-icon-background-color-dark;
      .icon {
        color: $panel-icon-color-dark;
      }
    }

    &.panel-integrations {
      border: 1px solid $basic-alpha-16;
      background-color: $bg-dark300;
      .list-integrations {
        background-color: $bg-dark200;
      }
    }
    &.segment-overrides {
      background-color: $bg-dark400;
      border: 1px solid $white-alpha-16;
    }
  }
}

a.text-white {
  &:hover {
    color: white !important;
  }
}

.panel {
  &.panel-integrations {
    border-radius: $panel-border-radius;
    border: 1px solid $panel-border-color;

    img {
      width: fit-content;
      height: 30px;
    }
    .list-integrations {
      border-radius: $border-radius-xlg;
      background-color: $bg-light200;
      font-size: $font-sm;
      line-height: $line-height-sm;
    }
  }
  .panel-content {
    .search-list {
      border: 1px solid $panel-border-color;
      border-radius: $panel-border-radius;
      .list-item {
        border-top: 1px solid $panel-border-color;
        &:first-child {
          border-top: none;
        }
      }
    }
  }
  &.panel-override {
    .panel-content {
      .search-list {
        border: none;
        .list-item-override {
          border: 1px solid $basic-alpha-16;
          border-radius: $border-radius-lg;
        }
      }
    }
  }
  &.panel-projects {
    .panel-content {
      .search-list {
        border: none;
        background-color: transparent;
      }
    }
  }
  &.panel-change-request {
    .search-list {
      border-color: rgba(149, 108, 255, 0.48);

      .table-header {
        color: #6837fc;
        background-color: rgba(102, 51, 255, 0.08);
      }

      .change-request-item {
        border-color: rgba(149, 108, 255, 0.48);
      }
    }

    .change-request-updated-value {
      input,
      code.txt {
        color: #6837fc;
      }
    }
  }
}

@include media-breakpoint-down(xxl) {
  .owners-title {
    display: none;
  }
  .table-column.assignees-column {
    display: block;
    width: 98px !important;
  }
}
